<template>
  <div>

  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
    mounted() {
        this.setToken(this.$route.params.token);
        this.changeView()
    },
    methods: {
    ...mapMutations("LogIn", [
      "setToken",
    ]),
    changeView(){
      this.$router.push('/')
    }
  },
}
</script>

<style>

</style>